import * as yup from 'yup';
import React, { useEffect, useState } from 'react'
import {
    Box, Button, Container, 
    FormControl, FormControlLabel, FormLabel, 
    InputLabel, MenuItem, Paper, Radio, RadioGroup, Select,useTheme
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Input } from '../form';
import { PnoWrap } from '../paragraphy'
import {  Head, inscription_services, usersServices } from '../../shared'
import { MenuDrawer } from '../Dashboard';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';


const schema = yup.object().shape({
    name: yup.string().required('nome é obrigatório'),
    birthdate: yup.string(),
    gender: yup.string().oneOf(['M', 'F']),
    civil_status_id: yup.number().notRequired(),
    email: yup.string().required('campo obrigatório').email(),
    profession: yup.string().optional(),
    country_id: yup.number().optional(),
    province_id: yup.number().optional(),
    is_student: yup.number().optional(),
    status: yup.number().optional(),
    role_id: yup.number().required('Perfil de usuario é obrigatório'),
    password: yup.string().optional()

})

export function AddAndUpdateUser() {
    const params = useParams()
    const [isUpdate, setIsUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { token, getRules, addUser } = useAuthContext()

    const { palette } = useTheme();
    const [countries, setCountries] = useState([])
    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            name: "",
            birthdate: "",
            gender: "",
            civil_status_id: null,
            email: '',
            profession: '',
            country_id: null,
            province_id: null,
            is_employee: 1,
            is_student: 0,
            status: null,
            role_id: null,
            password: '',
            contacts: [],
            // menus: [],
            // permissions: [],
            natural_from: null,
            email_verified_at: null,


        }
    })

    const [functionaryUpdate, setFunctionaryUpdate] = useState(null);
    const [countrySelected, setCountrySelected] = useState(null);
    const [province, setProvince] = useState([]);
    const [rules, setRules] = useState([]);

    const [civilState, setCivilState] = useState([
        {
            id: '1',
            name: 'Solteiro',
            status: false,
            sort_order: false
        },
        {
            id: '2',
            name: 'Casado',
            status: false,
            sort_order: false
        },
        {
            id: '3',
            name: 'Divorciado',
            status: false,
            sort_order: false
        }
    ]);

    const handleGetRules = async () => {

        const result = await getRules()
    
        if(result instanceof Error) {
          console.log(result);
        }
        
        setRules(result.data)
        console.log({r: result});
    }

    useEffect(() => {
        handleGetRules()
    }, [])

    useEffect(() => {
        if (params && params.id && typeof Number(params.id) === 'number') {
            setIsUpdate(true)
            usersServices.getOne(token, params.id).then((data) => {
                console.log({s: data.data});
                setFunctionaryUpdate(data.data)
            }).catch(err => {
                console.log({ err });
            })
        }
    }, [params, isUpdate])


    useEffect(() => {
        if (functionaryUpdate) {
            setValue('name', functionaryUpdate.name);
            setValue('birthdate', functionaryUpdate.birthdate);
            setValue('gender', functionaryUpdate.gender);
            setValue('number_identity', functionaryUpdate.number_identity);
            setValue('status', functionaryUpdate.status);
            setValue('role_id', functionaryUpdate.role_id);
            setValue('civil_status_id', functionaryUpdate.civil_status_id);
            setValue('profession', functionaryUpdate.profession);
            setValue('country_id', functionaryUpdate.country_id);
            setValue('is_employee', functionaryUpdate.is_employee);
            setValue('is_student', functionaryUpdate.is_student);

            let aux = countries[countries.findIndex((v) => {
                if (v.phone === functionaryUpdate.country_id?.toString()) {
                    return v
                }
            })]
            setCountrySelected(aux)
        }

    }, [functionaryUpdate, countrySelected]);
    
    const getCountries = async () => {
        try {
            const { data } = await inscription_services.getAllCountry();
            setCountries(data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
      getCountries()
    }, [])
    
    const handleSelectCountry = async (id) => {
        
            setValue('country_id', id ?? '')
            try {
                const { data } = await inscription_services.getProvinceForCountry(id);
                console.log({ data }, ' \n province');
                setProvince(data)
            } catch (error) {
                console.log(error);

            }
        
    }

    const submitData = async (data) => {
        setLoading(true)
        try {
            const response = await addUser(data);
            console.log({response});

            if(response.error) {
                toast.error(response.error || 'Problemas ao cadastrar Usuario!!!')
                return;
            }

            toast.success('Usuario cadastrado com sucesso!!!')
            navigate('/people/functionary');

        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    }


    return (
        <MenuDrawer page={isUpdate ? 'Funcionários | actualização' : 'Funcionários | cadastros'}>
            <Head title='Funcionario | add' />
            <Box component={Paper} m={2} borderRadius={2}>
                <Box p={1} >
                    <Box component={Container} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                        {!isUpdate
                            ? (
                                <div style={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                    <h1 className='title'>Cadastro</h1>
                                    <p>Preencha todos os campos para efectuar o cadastro</p>
                                </div>
                            )
                            : <h1 mr={2} color={palette.success.main} fontWeight={'bold'} fontSize={20}>
                                Actualização
                            </h1>
                        }
                        <Box component={'form'} noValidate onSubmit={!!isUpdate ? handleSubmit(submitData) : handleSubmit(submitData)}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Nome Completo</InputLabel>
                                    <Controller
                                        control={control}
                                        name='name'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                
                                                onChange={onChange}
                                                value={value}
                                                disabled={!!isUpdate}
                                                placeholder={"Digite o nome do funcionário"}
                                                color='primary'
                                                error={!!errors.name}
                                                helperText={errors.name ? errors.name.message : ''}

                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <InputLabel className='label'>Data de nascimento</InputLabel>

                                    <Controller
                                        control={control}
                                        name='birthdate'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                type={'date'}
                                                onChange={onChange} value={value ?? ''}
                                                color='primary'
                                                error={!!errors.birthdate}
                                                helperText={errors.birthdate ? errors.birthdate.message : ''}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Email</InputLabel>
                                    <Controller
                                        control={control}
                                        name='email'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                
                                                onChange={onChange}
                                                value={value ? value.toString() : ''}
                                                type={'email'} color='primary'
                                                placeholder="Digite seu email"
                                                error={!!errors.email}
                                                helperText={errors.email ? errors.email.message : ''} />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%', marginTop: '1rem' }}>
                                    <InputLabel className='label' sx={{ mt: 1 }}>Estado cívil</InputLabel>
                                    <Controller
                                        control={control}
                                        name='civil_status_id'
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="demo-simple-select-label" >Selecione</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value?.toString() ?? ''}
                                                    label="Selecione"
                                                    error={!!errors.civil_status_id}
                                                    onChange={onChange}
                                                >
                                                    {civilState.length > 0 && civilState.map((el, id) => (
                                                        <MenuItem key={id} value={el.id}><PnoWrap color='GrayText' text={el.name} /></MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.civil_status_id && <PnoWrap color='error' fontSize={12} mt={.4} text={'estado cívil é Óbrigatório'} />}
                                            </FormControl>)}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Profissão</InputLabel>
                                    <Controller
                                        control={control}
                                        name='profession'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                
                                                onChange={onChange} value={value ?? ''}
                                                placeholder="Digite sua Profissão"
                                                color='primary'
                                                error={!!errors.profession}
                                                helperText={errors.profession ? errors.profession.message : ''} />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%', marginTop: '1rem' }}>
                                    <InputLabel className='label' sx={{ mt: 1 }}>País</InputLabel>
                                    <Controller
                                        control={control}
                                        name='country_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="demo-simple-select-label" >Selecione</InputLabel>
                                                <Select
                                                    
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value?.toString() ?? ''}
                                                    label="Selecione"
                                                    error={!!errors.country_id}
                                                    onChange={(evt) => handleSelectCountry(evt.target.value)}
                                                >
                                                    {countries.length > 0 && countries.map((el, id) => (
                                                        <MenuItem key={id} value={el.id}><PnoWrap color='GrayText' text={el.name} /></MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.country_id && <PnoWrap color='error' fontSize={12} mt={.4} text={'estado cívil é Óbrigatório'} />}
                                            </FormControl>)}
                                    />
                                </div>
                               
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginTop: '1rem' }}>
                                    <InputLabel className='label' sx={{ mt: 1 }}>Província</InputLabel>
                                    <Controller
                                        control={control}
                                        name='province_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="demo-simple-select-label" >Selecione</InputLabel>
                                                <Select
                                                    
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value?.toString() ?? ''}
                                                    label="Selecione"
                                                    error={!!errors.province_id}
                                                    onChange={onChange}
                                                >
                                                    {province.length > 0 && province.map((el, id) => (
                                                        <MenuItem key={id} value={el.id}><PnoWrap color='GrayText' text={el.name} /></MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.province_id && <PnoWrap color='error' fontSize={12} mt={.4} text={'Província é Óbrigatório'} />}
                                            </FormControl>)}
                                    />
                                </div>

                                <div style={{ width: '50%', marginTop: '1rem' }}>
                                    <InputLabel className='label' sx={{ mt: 1 }}>Status</InputLabel>
                                    <Controller
                                        control={control}
                                        name='status'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="demo-simple-select-label" >Selecione</InputLabel>
                                                <Select
                                                    
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value?.toString() ?? ''}
                                                    label="Selecione"
                                                    error={!!errors.status}
                                                    onChange={onChange}
                                                >
                                                        <MenuItem value={1}><PnoWrap color='GrayText' text={'Active'} /></MenuItem>
                                                        <MenuItem value={0}><PnoWrap color='GrayText' text={'Inactive'} /></MenuItem>
                                                   
                                                </Select>
                                                {errors.status && <PnoWrap color='error' fontSize={12} mt={.4} text={'estado é Óbrigatório'} />}
                                            </FormControl>)}
                                    />
                                </div>
                                
                            </div>
                            
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', marginRight: '1rem' }}>
                                    <InputLabel className='label'>Senha</InputLabel>
                                    <Controller
                                        control={control}
                                        name='password'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                
                                                onChange={onChange} value={value ?? ''}
                                                placeholder="Digite sua senha"
                                                color='primary'
                                                type={'password'}
                                                error={!!errors.password}
                                                helperText={errors.password ? errors.password.message : ''} />
                                        )}
                                    />
                                </div>
                                <div style={{ width: '50%', marginTop: '1rem' }}>
                                    <InputLabel className='label' sx={{ mt: 1 }}>Perfil</InputLabel>
                                    <Controller
                                        control={control}
                                        name='role_id'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id="demo-simple-select-label" >Selecione</InputLabel>
                                                <Select
                                                    
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value?.toString() ?? ''}
                                                    label="Selecione"
                                                    error={!!errors.role_id}
                                                    onChange={onChange}
                                                >
                                                    {rules.length > 0 && rules.map((el, id) => (
                                                        <MenuItem key={id} value={el.id}><PnoWrap color='GrayText' text={el.name} /></MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.role_id && <PnoWrap color='error' fontSize={12} mt={.4} text={'perfil é Óbrigatório'} />}
                                            </FormControl>)}
                                    />
                                </div>
                            </div>

                            <Box display={'flex'} justifyContent={'space-between'}>
                                <Box>
                                    <Controller
                                        control={control}
                                        name='gender'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl>
                                                <FormLabel className='label' id="demo-controlled-radio-buttons-group">
                                                    Gênero
                                                </FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <FormControlLabel value="M" control={<Radio />} label="Masculino" />
                                                    <FormControlLabel value="F" control={<Radio />} label="Femenino" />
                                                </RadioGroup>
                                                {errors.gender && <PnoWrap color='error' fontSize={12} mt={.4} text={'genero é Óbrigatório'} />}
                                            </FormControl>
                                        )}
                                    />
                                </Box>
                                <Box>
                                    <Controller
                                        control={control}
                                        name='is_student'
                                        rules={{
                                            required: 'Campo obrigatorio'
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl>
                                                <FormLabel className='lBEL' id="demo-controlled-radio-buttons-group">
                                                    É aluno desta instituição
                                                </FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <FormControlLabel value={1} control={<Radio />} label="Sim" />
                                                    <FormControlLabel value={0} control={<Radio />} label="Não" />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                </Box>
                            </Box>

                            <Box>
                                {isUpdate ?
                                    <Box sx={{ display: 'flex', my: 2 }}>
                                        <Button
                                            variant='contained'
                                            sx={{ mb: 2, py: 2, mr: 1 }}
                                            color='primary'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit()}
                                        >
                                            Actualizar
                                        </Button>
                                    </Box>
                                    : <Box sx={{ my: 2 }}>
                                        <Button
                                            sx={{ py: 2 }}
                                            variant='contained'
                                            color='success'
                                            fullWidth
                                            type='submit'
                                            onSubmit={handleSubmit(submitData)}
                                            disabled={loading}
                                        >
                                            Guardar
                                        </Button>
                                    </Box>}


                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </MenuDrawer>
    )
}