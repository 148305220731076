import React from 'react'

const Head = ({ title }) => {
    React.useEffect(() => {
        document.title = `INFQE | ${title}`;
    }, [title]);

    return (<></>);
}

export { Head }
