import React from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  Access, Dashboard, Professor, SeeMoreFunctionary, SignIn, Student,
  StudentEnrolled, StudentsPerformance, SeeMoreAluno,
  StudentSelected, Home,
} from '../pages'
import ProtectedRoutes from './Private.routes'
import {
  AddAndUpdateUser, AddAndUpdateStudent, AddAndUpdateCurso,
  AddAndUpdateDisciplina, AddAndUpdateEsola, AddAndUpdateClasses,
  AddAndUpdateLevel, AddAndUpdateLocation
} from '../components'
import { SessionContextProvider } from '../shared/contexts'
import Inscription from '../pages/inscription/Inscription'
import {
  Course, CourseDeleted, Escola, MoreInfoCourse, Disciplina,
  DisciplineDeleted, SeeMoreSchool, Level, LevelDeleted, Classes, ClassesDeleted,
  Location, LocationDeleted
} from '../pages/adicional'
import { FormTwo } from '../pages/inscription/Form2'
import { SchoolDeleted } from '../pages/adicional/SchoolDeleted'
import { Pauta } from '../pages/adicional/Pauta'
import { PautaFinal } from '../pages/adicional/PautaFinal'

export function RoutesApp() {
  return (
    <SessionContextProvider>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/inscription' element={<Inscription />} />
        {/* <Route path='/inscription/:id' element={<FormOne />} />  */}
        <Route path='/inscription/two' element={<FormTwo />} />

        <Route path='/login' element={<SignIn />} />
        <Route element={<ProtectedRoutes />}>
          <Route path='/dash' element={<Dashboard />} />
          {/* gestão de usuários */}
          <Route path='/people/functionary' element={<Professor />} />
          <Route path='/people/functionary/:id' element={<SeeMoreFunctionary />} />
          <Route path='/people/functionary/add' element={<AddAndUpdateUser />} />
          <Route path='/people/functionary/update/:id' element={<AddAndUpdateUser />} />

          <Route path='/people/students' element={<Student />} />
          <Route path='/people/students/show/:id' element={<SeeMoreAluno />} />
          <Route path='/people/students/register' element={<AddAndUpdateStudent />} />
          <Route path='/people/students/update/:id' element={<AddAndUpdateStudent />} />
          <Route path='/people/students/enrolled' element={<StudentEnrolled />} />
          <Route path='/people/students/selected' element={<StudentSelected />} />
          {/* <Route path='/people/students/inscription' element={<AddInscription />} /> */}

          <Route path='/people/access' element={<Access />} />

          {/* gestão de academica */}
          <Route path='/academic/school' element={<Escola />} />
          <Route path='/academic/school/show/:id' element={<SeeMoreSchool />} />
          <Route path='/academic/school/register' element={<AddAndUpdateEsola />} />
          <Route path='/academic/school/update/:id' element={<AddAndUpdateEsola />} />
          <Route path='/academic/school/erased' element={<SchoolDeleted />} />

          <Route path='/academic/pauta' element={<Pauta />} />
          <Route path='/academic/pauta/final' element={<PautaFinal />} />

          <Route path='/academic/courses' element={<Course />} />
          <Route path='/academic/courses/register' element={<AddAndUpdateCurso />} />
          <Route path='/academic/courses/show/:id' element={<Course />} />
          <Route path='/academic/courses/update/:id' element={<AddAndUpdateCurso />} />
          <Route path='/academic/courses/info/:id' element={<MoreInfoCourse />} />
          <Route path='/academic/courses/erased' element={<CourseDeleted />} />

          {/* <Route path='/academic/performance' element={<StudentsPerformance />} /> */}

          <Route path='/academic/discipline' element={<Disciplina />} />
          <Route path='/academic/discipline/register' element={<AddAndUpdateDisciplina />} />
          <Route path='/academic/discipline/update/:id' element={<AddAndUpdateDisciplina />} />
          <Route path='/academic/discipline/erased' element={<DisciplineDeleted />} />

          <Route path='/academic/levels' element={<Level />} />
          <Route path='/academic/levels/register' element={<AddAndUpdateLevel />} />
          <Route path='/academic/levels/update/:id' element={<AddAndUpdateLevel />} />
          <Route path='/academic/levels/erased' element={<LevelDeleted />} />

          <Route path='/academic/classes' element={<Classes />} />
          <Route path='/academic/classes/register' element={<AddAndUpdateClasses />} />
          <Route path='/academic/classes/update/:id' element={<AddAndUpdateClasses />} />
          <Route path='/academic/classes/erased' element={<ClassesDeleted />} />

          <Route path='/academic/location' element={<Location />} />
          <Route path='/academic/location/register' element={<AddAndUpdateLocation />} />
          <Route path='/academic/location/update/:id' element={<AddAndUpdateLocation />} />
          <Route path='/academic/location/erased' element={<LocationDeleted />} />
        </Route>

        <Route
          path='/*'
          element={
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '47vw'
            }}
            >
              <h1 className='title'>Página não encontrada :(</h1>
            </div>
          }
        />
      </Routes>
    </SessionContextProvider>
  )
}