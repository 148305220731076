import React from 'react'
import { TextField } from '@mui/material'

const Input = ({ label, type,errorText, id, name, value, onChange, ...rest }) => {
    return (
        <TextField
            label={label}
            type={type}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            fullWidth
            helperText={errorText}
            {...rest}
        />
    )
}

export {Input}
