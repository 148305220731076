import { Box, Button, CircularProgress, TextField, useTheme } from '@mui/material'
import React from 'react'
import { PnoWrap } from '../../../components'
import Input from '../../../components/formComponents/Input'
import { toast } from 'react-toastify'

export const SendNIf = ({ setClose, nif, onChangeNif, loading = false }) => {
  const { palette, zIndex } = useTheme()

  const handleSubmit = () => {
    if(nif && nif.length >= 8) {
      setClose()
    }else {
      toast.error('Precisa informar corretamente o Número de seu documento')
    }
  }

  return (
    <Box
      top={0}
      left={0}
      position={'absolute'}
      zIndex={zIndex.drawer + 15}
      width={'100vw'}
      height={'100vh'}
      sx={{ background: 'rgba(0,0, 0, .5)' }}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
        px={2}
        width={480}
        height={266}
        bgcolor={'white'}
        borderRadius={2}
        boxShadow={2}
        overflow={'hidden'}
      >
        <Box mt={4} display='flex' justifyContent='start'>
          <h1 className='title'>Informe nº do documento</h1>
        </Box>
        <Box width={'100%'} mt={1} marginRight={2}>
          <Input label='B.I / Passaporte'
            placeholder='Digite o seu número de B.I ou Passaporte'
            value={nif ?? ''}
            onChange={({ target }) => onChangeNif(target.value)}
          />
        </Box>
        <Box sx={{ width: '100%' }} >
          <Button
            variant='contained'
            onClick={handleSubmit}
            style={{ padding: '.6rem 1rem', background: '#FFBB11 !important', color: '#222' }}
          >
            {loading ?
              <CircularProgress size={'1rem'} color='success' /> : <>Continuar</>
            }
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
