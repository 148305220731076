
import { api_services } from "./config";


const POST_INSCRIPTION = async (data) => {
    console.log({data});
    try {
        const result = await api_services('/applications', {
            method: 'POST',
            headers: { 
                'Content-Type': 'multipart/form-data',
                'accept': '*/*'
             },
            body: data,
        })

        return result.json();

    } catch (error) {
        console.log({error});
        throw new Error(error.message)
    }
}


const getAllPeriod = async (page=1, limit=15, filter='', token = '') => {
    try {
        const result = await api_services(`/applications/periods?filter=${filter}&per_page=${limit}&page=${page}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getAllCandidate = async (page=1, limit=15, filter='', token = '') => {
    try {
        const result = await api_services(`/All/Candidates?filter=${filter}&per_page=${limit}&page=${page}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getAllCandidateSelected = async (page=1, limit=15, filter='nadi', token = '') => {
    try {
        const result = await api_services(`/Selected?filter=${filter}&per_page=${limit}&page=${page}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const GET_DIFICIENCE = async (page=1, limit=15, filter='', token = '') => {
    try {
        const result = await api_services(`/applications/debilities?filter=${filter}&per_page=${limit}&page=${page}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getDocsType = async () => {
    try {
        const result = await api_services(`/document_types?identification=1`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}


const getAllCountry = async () => {
    try {
        const result = await api_services(`/contries`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}


const setNote = async (data, token) => {
    try {
        const result = await api_services(`/MediaExamAccess`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        })
        console.log({result});
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const manualSelect = async (user_id, token) => {
    try {
        const result = await api_services(`/Selection/manual`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({user_id: [user_id]}),
        })
        console.log({result});
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const automaticSelect = async (token) => {
    try {
        const result = await api_services(`/Selection/automatica`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        console.log({result});
        return result.json();
    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

const getProvinceForCountry = async (country_id) => {
    try {
        const result = await api_services(`/contries/zones?country_id=${country_id}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}
/* 
const getProvinceForCountry = async (country_id) => {
    try {
        const result = await api_services(`/document_types?country_id=${country_id}`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
} */
const getSchoolType = async (country_id) => {
    try {
        const result = await api_services(`/locations/types`, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            },
        })

        return result.json();

    } catch (error) {
        console.log(error);
        throw new Error(error.message)
    }
}

export const inscription_services = {
    GET_DIFICIENCE,
    POST_INSCRIPTION,
    getAllPeriod,
    getDocsType,
    getAllCountry,
    getProvinceForCountry,
    getSchoolType,
    getAllCandidate,
    setNote,
    getAllCandidateSelected,
    manualSelect,
    automaticSelect,
    
}