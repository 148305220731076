// styles
import styles from './Home.module.css';
// dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../shared/hooks';
// components
import Header from '../components/Header';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Modal from './inscription/Modal';
// iamges
import avatar from '../assets/images/avatar/avatar-negro.jpg';
import imageObjective from '../assets/images/image-objectives.png';
import imageExigencia from '../assets/images/image-exigencia.png';

export function Home() {
  const [modal, setModal] = React.useState(false);
  const navigate = useNavigate();
  const { user } = useAuthContext();

  React.useEffect(() => {
    if (user) navigate('/dash');
  }, [user]);

  return (
    <section className={styles.home}>
      <Header />
      <Banner setModal={setModal} />
      {modal && <Modal setModal={setModal} />}

      <article className={styles.articleOne}>
        <div className={styles.blobkImage}>
          <img src={avatar} alt='' />
        </div>
        <div className={styles.blobkText}>
          <h2 className='title'>INFQE: Sistema de gestão escolar</h2>
          <p>
            O INFQE é uma plataforma modular e escalável, desenvolvida
            para atender às diversas necessidades das instituições de
            ensino, sejam elas de pequeno, médio ou grande porte. Com
            funcionalidades específicas para gerenciar processos educacionais,
            administrativos e financeiros, o sistema oferece uma gestão
            eficiente e personalizada, adaptando-se facilmente a diferentes
            contextos e complexidades.
          </p>
          <button className={styles.button}>Saber mais</button>
        </div>
      </article>

      <article className={styles.articleTwo}>
        <div className={styles.blobkText}>
          <h2 className='title'>Objectivos</h2>
          <ul>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Centralização de Informações: Reunir dados de várias escolas
                em uma única plataforma.
              </span>
            </li>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Gestão Administrativa: Automatizar processos administrativos, como
                matrícula, registro de notas.
              </span>
            </li>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Comunicação Eficiente: Facilitar a comunicação entre escolas, alunos,
                pais e professores.
              </span>
            </li>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Relatórios e Análises: Gerar relatórios detalhados sobre desempenho
                acadêmico, finanças e outros indicadores.
              </span>
            </li>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Personalização do Ensino: Proporcionar ferramentas para acompanhamento
                individualizado do progresso dos alunos.
              </span>
            </li>
          </ul>
        </div>
        <div className={styles.blobkImage}>
          <img src={imageObjective} alt='image-objectivos' className={styles.imageObjective} />
        </div>
      </article>

      <article className={styles.articleThree}>
        <div className={styles.blobkImage}>
          <img src={imageExigencia} alt='image-objectivos' className={styles.imageExigencia} />
        </div>

        <div className={styles.blobkText}>
          <h2 className='title'>Exigências</h2>
          <ul>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Não é necessária experiência em Sistemas - vou te ensinar
                tudo o que você precisa saber.
              </span>
            </li>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Um dispositivo com acesso à Internet.
              </span>
            </li>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Nenhuma experiência anterior de codificação é necessária.
              </span>
            </li>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Todos as elementos usados ​​neste Sistema serão gratuitos.
              </span>
            </li>
            <li>
              <i className="fas fa-check"></i>
              <span>
                Qualquer sistema operacional: Mac, PC, Linux.
              </span>
            </li>
          </ul>
        </div>
      </article>

      <article className={styles.articleFor}>
        <div>
          <h2 className='title'>Instituições Inscritas</h2>
        </div>
        <div className={styles.bannerBlock}>
          <h2>Instituto Médio de Economia de Luanda (IMEL)</h2>
          <h2>Instituto Médio Industrial de Luanda (IMIL)</h2>
          <h2>Instituto Médio Normal de Educação Garcia Neto</h2>
          <h2>Instituto Médio Industrial Simione Mucune (IMISM)</h2>
          <h2>Instituto Médio de Telecomunicações (ITEL)</h2>

          <p>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <span>4,7/5 (1023 Visualizações) 20327 Esudantes</span>
          </p>
        </div>
      </article>


      <Footer />
    </section>
  )
}
