import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ImgAvatar from '../../assets/images/avatar/avatar-negro-2.jpg';
import { RoutesList } from './pathAndRouts'
import { ItemMenu } from './itemMenu'
import { Avatar, Button, Menu, MenuItem, Tooltip, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { SessionContext } from '../../shared/contexts';

const drawerWidth = 330;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      background: theme.palette.secondary.main,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',

        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7),
        },
      }),
    },
  }),
);

const settings = ['Perfil', 'Saír'];

export function MenuDrawer({ children, page = 'Dashboard' }) {
  const { user, logout } = React.useContext(SessionContext);
  const [open, setOpen] = React.useState(true);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { palette } = useTheme();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [matches, setMatches] = React.useState(false);

  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 900px)');

    const handleChange = (e) => {
      setMatches(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);
    handleChange(mediaQuery);

    if (matches) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [matches]);

  return (
    <Box sx={{ display: 'flex' }} height={'100vh'} width={'100vw'} bgcolor={palette.background.default}>
      <AppBar position="absolute" open={open}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="#333"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {page}
          </Typography>
          <Box display={'flex'} gap={4}>
            <IconButton color="primary">
              <Badge badgeContent={4} sx={{
                '& .MuiBadge-badge': { backgroundColor: palette.secondary.main, color: palette.background.paper }
              }}>
                <NotificationsIcon sx={{ color: '#fff' }} />
              </Badge>
            </IconButton>
            {
              user && (
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Mais opções">
                    <Button onClick={handleOpenUserMenu}
                      sx={{ p: 0 }}>
                      <Avatar src={ImgAvatar} alt={user.name} sx={{ mr: 2 }} />
                      <div>
                        <p className='username'>{user.name}</p>
                        <p className='email'>{user.email}</p>
                      </div>
                    </Button>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '50px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{ horizontal: 'left', vertical: 'top', }}
                    keepMounted
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem key={setting} onClick={() => { setting === 'Saír' ? logout() : handleCloseUserMenu() }} sx={{ width: '5rem' }}>
                        <Typography textAlign="left">{setting}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              )
            }
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [1],
            bgcolor: palette.primary.dark,
            color: '#222'
          }}
        >
          <Link to='/dash' className='logo'  >
            <Typography color='#333' fontWeight={'bold'}>
              <i className="fa-solid fa-layer-group" color='#333'></i> INFQE
              </Typography>
          </Link>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon sx={{ color: '#333' }} />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component={'nav'} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
          <Box >
            {RoutesList &&
              RoutesList.map((item, id) => (
                <ItemMenu key={id} title={item.title} to={item.to} mainIcon={item.mainIcon} subMenu={item.subMenu} />
              ))
            }
          </Box>
        </List>
      </Drawer>
      <Box pt={8} overflow={'auto'} flex={1}>
        {children}
      </Box>
    </Box>
  );
}