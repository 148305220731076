import { api_services } from './config';


const login = async (data) => {    
    try {
        const result = await api_services('/auth/login', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao logar', error);  
        return error;      
    }
}

const me = async (token) => {    
    try {
        const result = await api_services('/auth/me', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao me', error);  
        return error;      
    }
}

const CreateRules = async ({name, permissions = []}, token) => {    
    try {
        const result = await api_services('/auth/roles', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({name, permissions})
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao Cadastrar Regras', error);  
        return error;      
    }
}

const updateRules = async (name, permissions = [], id_rules, token) => {   
    
    try {
        const result = await api_services(`/auth/roles/${id_rules}`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({name, permissions})
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao Cadastrar Regras', error);  
        return error;      
    }
}

const getRules = async (token) => {    
    try {
        const result = await api_services('/auth/roles', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao buscar as Regras', error);  
        return error;      
    }
}


const getPermissions = async (token) => {    
    try {
        const result = await api_services('/auth/permissions', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao buscar as Regras', error);  
        return error;      
    }
}


const getPermissionsRules = async (token) => {    
    try {
        const result = await api_services('/auth/permissions/roles', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            }
            
        })
        return result.json()
    } catch (error) {
        console.log('Erro ao buscar as Regras', error);  
        return error;      
    }
}
const loginRegister = async (data, token) => {
    console.log({...data});
    try {
        const result = await api_services('/auth/register', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({...data})
        })
         return result.json();
    } catch (error) {
        console.log('Erro ao registrar sessão', error);        
    }
}

export const sessionServices = {
    login,
    loginRegister,
    me,
    CreateRules,
    updateRules,
    getRules,
    getPermissions,
    getPermissionsRules
    
}