import { setTokenLocalData, 
    cleanLocalData, 
    deleteTokenLocalData, 
    deleteUserLocalData, 
    getTokenLocalData,
    getUserLocalData, 
    setUserLocalData,
    getCandidateLocalData,
    setCandidateLocalData,
    deleteCandidateLocalData
} from "../LocalData"

export const useLocalData = () => {
    return {
        setTokenLocalData,
        cleanLocalData, 
        deleteTokenLocalData, 
        deleteUserLocalData, 
        getTokenLocalData,
        getUserLocalData, 
        setUserLocalData,
        getCandidateLocalData,
        setCandidateLocalData,
        deleteCandidateLocalData
    }
} 