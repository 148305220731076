import { Box, Button, CircularProgress, LinearProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { Input, MenuDrawer } from "../../components";
import { Environment, Head } from "../../shared";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../shared/hooks";


const label = { inputProps: { 'aria-label': 'Switch demo' } };

export function Access() {
  const [data, setData] = useState([]);
  const { palette } = useTheme()
  const [profile, setProfile] = useState(null);

  const [change, setChange] = useState(false)
  const { addRules, getPermissions, updatePermissionsRules, getPermissionsRules, isLoading } = useAuthContext()
  
  const handleGetRules = async () => {

    const result = await getPermissionsRules()

    if(result instanceof Error) {
      console.log(result);
    }
    
    setData(result.data)
  }
 
  const handleGetPermissions = async () => {

    const result = await getPermissions()

    if(result instanceof Error) {
      console.log(result);
    }
  }
  
  useEffect(() => {
    handleGetRules()
    handleGetPermissions();
  }, [change])
  
  const handleSaveProfile = async () => {
    if(!profile) return toast.error('O campo é obrigatório!');

    const result = await addRules({name: profile, permissions: []})

    if(result instanceof Error) {
      toast.error('Problemas ao salvar o perfil')
    }
      toast.success('Perfil salvo com sucesso!')
  }


  const handleChangeRules = async (name, rules_id, permissions_id) => {
    const profile = data[data.findIndex(el => el.id === rules_id)];
    const permissions = []
    
    profile?.permissions.forEach(el => permissions.push(el.id));

    if(permissions.indexOf(permissions_id) !== -1) {
      permissions.splice(permissions.indexOf(permissions_id), 1)
    }else {
      permissions.push(permissions_id)
    }
    
    try {
      await updatePermissionsRules(name, permissions, rules_id)
      setChange(!change);
      toast.success('Perfil actualizado com sucesso!')
    } catch (error) {
      console.log({error});
    }
  }

  return (

    <MenuDrawer page='Controle de acesso'>
      <Head title='Cadastra Perfil de acesso' />
      <Box component={Paper} p={2} m={2} flex={1}>
        <Box mt={1} mb={3} px={1}>
          <h1 className="title">Cadastra Perfil de acesso</h1>
          <p>Preencha todos os campos para Registrar novo perfil de acesso</p>
        </Box>
        <Box >
          <Box display={'flex'} gap={1} mb={2} mx={1}>
            <Box width={'100%'}>
              <Box display={'flex'} gap={1}>
                <Typography fontSize={14}>Titulo do perfil</Typography>
                <Typography fontSize={14} color={'error'}>*</Typography>
              </Box>
              <Box>
                <Input 
                  fullWidth 
                  placeholder='Ex: Admin'
                  value={profile??''}
                  onChange={(e) => setProfile(e.target.value)} />
              </Box>
              <Button 
                variant="contained"
                sx={{mt: 2}}
                onClick={() => handleSaveProfile()}
                disabled={isLoading}
                endIcon={isLoading ? <CircularProgress size={'1rem'}/> : undefined}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Box>
        <Box >
          <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
            <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
              <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                  <TableRow>
                    <TableCell className='title-table text-center'>Perfis</TableCell>
                    <TableCell className='title-table text-center'>Listar</TableCell>
                    <TableCell className='title-table text-center'>Criar</TableCell>
                    <TableCell className='title-table text-center'>Visualizar</TableCell>
                    <TableCell className='title-table text-center'>Editar</TableCell>
                    <TableCell className='title-table text-center'>Excluir</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 &&
                    data.map((item, id) => (
                      <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell className='text-center'>{item.name}</TableCell>
                          <TableCell className='text-center'>
                            <Switch {...label} checked={item?.permissions.findIndex(el => el.name === 'list') !== -1} onChange={() => handleChangeRules(item.name, item.id, 1)}/>
                          </TableCell>
                          <TableCell className='text-center'>
                            <Switch {...label} checked={item?.permissions.findIndex(el => el.name === 'create') !== -1} onChange={() => handleChangeRules(item.name, item.id, 2)}/>
                          </TableCell>
                          <TableCell className='text-center'>
                            <Switch {...label} checked={item?.permissions.findIndex(el => el.name === 'view') !== -1} onChange={() => handleChangeRules(item.name, item.id, 4)}/>
                          </TableCell>
                          <TableCell className='text-center'>
                            <Switch {...label} checked={item?.permissions.findIndex(el => el.name === 'edit') !== -1} onChange={() => handleChangeRules(item.name, item.id, 3)}/>
                          </TableCell>
                          <TableCell className='text-center'>
                            <Switch {...label} checked={item?.permissions.findIndex(el => el.name === 'delete') !== -1} onChange={() => handleChangeRules(item.name, item.id, 5)}/>
                          </TableCell>
                      </TableRow>
                    ))
                  }

                  {false && (
                    <TableRow>
                      <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                    </TableRow>)
                  }

                </TableBody>
                <TableFooter>
                  {data.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={12} align="center"><Typography width={250}>Lista de produtos vazia</Typography></TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                      <Pagination
                        count={Math.ceil(1 / Environment.ROW_LIMIT)}
                        page={Number(1)} /* onChange={(_, newPage) => setSearchParmams({ page: newPage.toString() }, { replace: true })} */
                        color='primary'
                        size='small'
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>

    </MenuDrawer>
  )
}


