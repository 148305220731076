import { Link, useNavigate } from 'react-router-dom';
import styles from './Modal.module.css';
import React from 'react';


import imgDown from '../../assets/image_processing20200816-31027-1c88k16.gif'


const ModalDownPdf = ({ setModal, link }) => {

    const navigate = useNavigate()

    const closeModal = () => {
        setModal(false)
        navigate('/')
    }

    return (
        <section className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.hedaer}>
                    <h1 className='title'>Baixe o comprovante</h1>
                    <button className={styles.close} title='Fechar' onClick={() => closeModal()}>
                        X
                    </button>
                </div>
                
                <div className={styles.body}>
                    <Link to={link} className={styles.nacional}>
                        <div>
                            <img alt='download photo' src={imgDown} width={100}/>
                        </div>
                    </Link> 
                </div>
            </div>
        </section>
    )
}

export default ModalDownPdf
