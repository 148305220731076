import { PnoWrap } from '../paragraphy'
import styles from './Select.module.css'
import React from 'react'

const Select = ({ id, name, values, label, onChange, value }) => {
    return (
        <div className={styles.wrapper}>
            <label htmlFor={id}><PnoWrap color='grayText' text={label} /></label>
            <select id={id} name={name} onChange={onChange} value={value??''}>
                <option>Selecione uma opção</option>
                {values && values.map((item) => (
                    <option value={item.id} key={item.id}>{item.name}</option>
                ))}
            </select>
        </div>
    )
}

export default Select
