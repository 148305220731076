const Candidate_DTO = '@STUDENT_DTO';

export const getCandidateLocalData = () => {
    const data = localStorage.getItem(Candidate_DTO);
    return data;
}

export const setCandidateLocalData = (data) => {
    localStorage.setItem(Candidate_DTO, JSON.stringify(data));
    return;
}

export const deleteCandidateLocalData = (data) => {
    localStorage.removeItem(Candidate_DTO, JSON.stringify(data));
    return;
}
