import React, { useMemo } from 'react'
import { MenuDrawer } from '../../components'
import { Environment, studentServices, Head } from '../../shared'
import {
  Box, LinearProgress, Pagination,
  Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
  Typography
} from '@mui/material'
import { useSearchParams, Link } from 'react-router-dom';

export function Student() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalDatacount, setTotalDateCount] = React.useState(20);
  const [SearchParmams, setSearchParmams] = useSearchParams({ page: 1 });

  const page = useMemo(() => {
    return SearchParmams.get('page') || ''
  }, [SearchParmams])

  React.useEffect(() => {
    studentServices.getAll(page, Environment.ROW_LIMIT)
      .then((data) => setData(data))
      .catch((error) => console.error(error))
  }, [page, data])

  const deletestudent = async (id) => {
    try {
      await studentServices.delOne(id);
      alert('Alunos eliminado com sucesso')
    } catch (error) {
      console.log(error);
    }
  }

  return (

    <MenuDrawer page='Alunos'>
      <Head title='Alunos' />
      <Box component={Paper} py={2} m={2} flex={1}>
        <Box m={1} ml={2}>
          <section className='bloco-pesquisa-botao'>
            <div className='search'>
              <input type='text' placeholder='Pesquisar' />
              <button><i className='fas fa-search'></i></button>
            </div>

            <Link to='/people/students/register' className='btn btn-add'>
              Cadastrar Aluno <i className='fas fa-plus ml-1'></i>
            </Link>
          </section>
        </Box>

        <Box m={1} ml={2}>
          <Box marginBottom={5}>
            <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
              <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                <Table sx={{ minWidth: 650, color: '#333', background: '#E19E06 !importtant' }} size='small' aria-label="a dense table">
                  <TableHead style={{ background: '#E19E06 !importtant'}}>
                    <TableRow>
                      <TableCell className='title-table text-center'>Nº de Processo</TableCell>
                      <TableCell className='title-table text-center'>Nome Completo</TableCell>
                      <TableCell className='title-table text-center'>Curso</TableCell>
                      <TableCell className='title-table text-center'>Turma</TableCell>
                      <TableCell className='title-table text-center'>Acções</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0 &&
                      data.map((item) => (
                        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className='text-center'>{item.processo}</TableCell>
                          <TableCell className='text-center'>{item.nome}</TableCell>
                          <TableCell className='text-center'>{item.curso}</TableCell>
                          <TableCell className='text-center'>{item.turma}</TableCell>

                          <TableCell align="center">
                            <Box display={'flex'} gap={1} justifyContent={'center'}>
                              <Link className='btn-icon' to={`/people/students/show/${item.id}`}>
                                <i className="fa-solid fa-eye"></i>
                              </Link>
                              <Link to={`/people/students/update/${item.id}`}>
                                <Typography className='btn-icon btn-edit'>
                                  <i className="fa-solid fa-edit"></i>
                                </Typography>
                              </Link>
                              <Typography className='btn-icon btn-edit' onClick={() => deletestudent(item.id)}>
                                <i className="fa-solid fa-trash"></i>
                              </Typography>
                              <Link className='btn-icon' to={`/people/students/update/${item.id}`}>
                                <i className="fa-solid fa-print"></i>
                              </Link>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    }

                    {loading && (
                      <TableRow>
                        <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                      </TableRow>)
                    }

                  </TableBody>
                  <TableFooter>
                    {data.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={12} align="center"><Typography width={250}>Lista de produtos vazia</Typography></TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                        <Pagination
                          count={Math.ceil(totalDatacount / Environment.ROW_LIMIT)}
                          page={Number(page)} onChange={(_, newPage) => setSearchParmams({ page: newPage.toString() }, { replace: true })}
                          color='primary'
                          size='small'
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>

          </Box>
        </Box>

      </Box>

    </MenuDrawer>
  )
}
