import * as React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// dependencies
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginValidationSchema } from '../../shared/validations/login';
// components
import { Head } from '../../shared';
import { CircularProgress, Paper, TextField } from '@mui/material';
import { useAuthContext } from '../../shared/hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export function SignIn() {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const [existUser, setExistUser] = React.useState(false)
  const navigate = useNavigate()
  const { user, session, isLoading } = useAuthContext()

  React.useEffect(() => {
    if (user) navigate('/dash')
  }, [user, existUser])

  const onSubmit = async ({ email, password }) => {
    try {
      const result = await session(email, password);

      if(result){
        toast.success('Seja Bem vindo!')
        // document.location.href = '/dash'
        setExistUser(true)
      }

    } catch (error) {
      toast.error(error.message)
    }
  };

  return (
    <Box height={'100vh'} width={'100vw'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Head title='Login' />
      <Container component={Paper} elevation={2} maxWidth='xs'>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h6">
            <i className="fa-solid fa-layer-group"></i> INFQE
          </Typography>
          <Typography sx={{ mt: 3 }}>Inicie sessão e gerencie seu dados </Typography>
          <Box component="form" noValidate sx={{ mt: 1, }} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              margin="normal"
              fullWidth
              type='email'
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              required
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              type='password'
              id="password"
              label="Senha"
              name="password"
              autoComplete="password"
              required
              {...register('password')}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            {/* <Grid container>
              <Grid item xs>
                <Link to="" variant="body2">Esqueceu a senha?</Link>
              </Grid>
            </Grid> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 4, py: 1.5 }}
              onClick={handleSubmit(onSubmit)}
              endIcon={isLoading && <CircularProgress size={'1rem'} />}
              disabled={isLoading}
            >
              Entrar
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
